






import { Component, Vue } from 'vue-property-decorator';
import Page from '@/components/Page.vue';
import PortalNeotvRssTable from '@/modules/portal/components/PortalNeotvRssTable.vue';
import { RssService } from '@/modules/portal/services/RssService';
import { Rss } from '@/modules/portal/domain/Rss';


@Component({
  components: { PortalNeotvRssTable, Page},
})
export default class StationsView extends Vue {
  private isLoading: boolean = false;
  private rss: Rss | Rss = {
    items: [{
      id: '',
      enclosures: [],
    }]};

  private async mounted() {
    if (this.$route.params.code) {
      this.isLoading = true;
      await this.getRss(this.$route.params.code);
      this.isLoading = false;
    }
  }

  private async getRss(code: string) {
      this.rss = await RssService.getPubs(code);
  }
}
